import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicyPage = () => (
    <Layout>
        <SEO title="Contacto" />
        <div className="inner-banner">
            <div className="container">
                <div className="inner-title text-center">
                    <h3>Política de privacidad</h3>
                    <ul>
                        <li>
                            <a href="index.html">Inicio</a>
                        </li>
                        <li>
                            <i className="bx bx-chevron-right"></i>
                        </li>
                        <li>Política de privacidad</li>
                    </ul>
                </div>
            </div>
            <div className="inner-banner-shape">
                <div className="shape-one">
                    <StaticImage src="../images/inner-banner/banner-shape1.png" alt="Images" />
                </div>
                <div className="shape-two">
                    <StaticImage src="../images/inner-banner/banner-shape2.png" alt="Images" />
                </div>
                <div className="shape-three">
                    <StaticImage src="../images/inner-banner/banner-shape3.png" alt="Images" />
                </div>
                <div className="inner-banner-dots-2">
                    <StaticImage src="../images/shape/dots-shape.png" alt="Images" />
                </div>
            </div>
        </div>
        <div className="privacy-policy-area pt-50 pb-70">
            <div className="container">
                <div className="single-content">
                    <h2>INTRODUCCIÓN</h2>
                    <p>De acuerdo con la Ley 1581 de 2012 y sus decretos reglamentarios, UGNI S.A.S. (en adelante “Ugni”) adopta la presentepolítica de protección de datos personales y privacidad, la cual regulala recolección, almacenamiento, tratamiento, protección y administraciónde los datos que “Ugni”, obtenga de sus clientes, proveedores, empleadosy demás personas con las cuales Ugni tenga o llegue a tener alguna relación de cualquier naturaleza.</p>
                    <p>Ugni podrá modificar la presente política, con el fin de adecuarla acambios en la legislación y jurisprudencia vigentes y adaptarla aprácticas de industria. En ese evento, anunciará en su página web, o porotros medios adecuados, la introducción de tales cambios con la debida anticipación.</p>
                    <p>La información que libre y voluntariamente los clientes, proveedores,empleados o cualquier otra persona suministre a Ugni por cualquier medio,es introducida en sus bases de datos, de las cuales Ugni es responsable.</p>
                    <h2>ASPECTOS GENERALES DEL TRATAMIENTO</h2>
                    <h3>Identificación del Responsable del Tratamiento:</h3>
                    <p>UGNI S.A.S., identificada con NIT. 901279446 – 8, con domicilio principal en la ciudad de Bogtá, Departamento de Cundinamarca, en la dirección Calle 142 No. 20 - 56; página web: www.ugni.io, correo electrónico: info@ugni.io teléfono: +57 3012277865.</p>
                    <h3>Naturaleza de la Información Personal Recolectada:</h3>
                    <p>Ugni recolectará información de contacto y cualquier otra información quesea pertinente de acuerdo con la naturaleza de la relación que Ugnisostenga con el titular del dato, sea esta de carácter laboral, comercial o de cualquier otra índole.</p>
                    <p>En general, los datos personales que Ugni recolecta, tienen la siguiente naturaleza dependiendo del titular del dato, así:</p>
                    <p><span>Interesados en relaciones     comerciales.</span> Personas que por su propia voluntad contactan a Ugni a través de sus sitios web, correo electrónico, info@ugni.io llamadas telefónicas u otros medios, con el fin de solicitar información acerca de sus productos o servicios, evaluar posibilidades de alianzas comerciales, proveer a Ugni sus productos y servicios y en general, evaluar la posibilidad de desarrollar algún tipo de relación con la compañía. En estos casos la información que se solicita es de carácter básico, relacionada con la identificación de la persona y compañía a la que pertenece, cargo, correo electrónico, info@ugni.io teléfono, ciudad y asunto. En ocasiones, al ser contactados a través del sitio web de Ugni, los servidores de la compañía obtienen, para propósitos estadísticos, datos en relación con el sistema operativo que utiliza la persona que contacta a Ugni, la versión y el tipo de browser y la dirección IP.</p>
                    <p><span>Cliente, proveedores y     otros.</span> Cuando se concreta una relación comercial definitiva, Ugni recolecta la información necesaria para gestionar de manera adecuada dicha relación, de acuerdo con los parámetros establecidos en la presente política y cumpliendo todos los requisitos de ley y de los contratos respectivos.</p>
                    <p><span>Interesados en vincularse laboralmente a Ugni.</span> Personas que por su propia voluntad envían su Hoja de Vida a la compañía, con el objeto de ser tenidas en cuenta en los procesos de selección. La información suministrada en la Hoja de Vida es utilizada únicamente para efectos del proceso de selección, reclutamiento y contratación, por el personal del área encargada de llevar a cabo dichos procesos. Ugni no comparte la información recolectada con ninguna persona natural o jurídica ajena a la compañía.</p>
                    <p><span>Empleados.</span> La información sobre los empleados es estrictamente confidencial y se ajusta a los requisitos de la ley colombiana. Solo es utilizada por quienes, en razón de su cargo, tienen que ver con la gestión de la relación laboral. Ugni no hace púbico datos o información relacionada con los empleados a personas ajenas a la relación laboral o a entidades y personas ajenas a la compañía.</p>
                    <h3>Fuentes de la información:</h3>
                    <p><strong>Ugni</strong> recolecta información de las siguientes fuentes:</p>
                    <p><span>Directamente del titular de la  información.</span> </p>
                    <p><span>Automáticamente cuando el     titular de la información utiliza los sitios web de Ugni.</span> En los sitios web de Ugni se utilizan cookies y otras herramientas que recolectan información de quienes los visitan; por el simple hecho de ingresar a dichos sitios web puede obtenerse de manera automática la siguiente información:</p>
                    <ul>
                        <li>Los hipervínculos a los que ha dado click.</li>
                        <li>Información acerca del explorador que utiliza.</li>
                        <li>Detalles de las páginas que ha visto.</li>
                        <li>Su dirección IP</li>
                        <li>Los sitios que visitó antes de llegar al Portal</li>
                    </ul>
                    <p>Teniendo en cuenta lo anterior, si el Titular de la información NO quiereque la misma sea recolectada automáticamente, deberá deshabilitar de su explorador de Internet la configuración de aceptación automática. Allípodrá bloquearla, así como detectar cuando dicha información esté siend oenviada a su equipo. Es de tener en cuenta que, si se deshabilitan las cookies, se puede ver afectada la experiencia en el sitio Web.</p>
                    <p><span>De otras fuentes.</span> Ugni podrá obtener información personal de bases de datos públicas o de terceras personas autorizadas por el titular de los datos para compartir información.</p>
                    <h3>Tratamiento al que serán sometidos los datos.</h3>
                    <p>La información personal obtenida por Ugni, podrá ser objeto dealmacenamiento, uso, circulación o supresión, según sea la finalidad para la cual haya sido recolectada, y/o de acuerdo con lo establecido en la ley.</p>
                    <p>La información sujeta a Tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible.</p>
                    <h3>Finalidad del tratamiento.</h3>
                    <p>La información recolectada por Ugni se utiliza, con la autorización de los titulares de la misma, para los siguientes fines:</p>
                    <ol>
                        <li>Ofrecer información sobre productos y servicios, así como de     oportunidades comerciales.</li>
                        <li>Materializar potenciales relaciones jurídicas con personas     interesadas en ser clientes, proveedores o empleados de Ugni.</li>
                        <li>Prestar al titular de la información los servicios contratados, así    como la asistencia, consultoría y soporte necesarios para cumplir     las obligaciones emanadas de la relación comercial.</li>
                        <li>Mantener actualizados a los clientes sobre el desarrollo de los    procesos comerciales que se adelanten, así como de la oferta o    ejecución de servicios prestados por Ugni incluyendo los servicios en     la nube.</li>
                        <li>Realizar las gestiones necesarias para dar cumplimiento a las    obligaciones inherentes a los servicios y productos contratados con     Ugni.</li>
                        <li>Realizar actividades de mercadeo, promoción, publicidad, compras,    mejoras en el servicio y producto, consultas, controles,    verificaciones, alianzas, acuerdos, así como cualquier otra    actividad relacionada con los servicios de Ugni y sus productos     actuales y futuros.</li>
                        <li>Llevar estadísticas internas y evaluar la calidad de los productos y    servicios ofrecidos, así como el nivel de satisfacción de clientes,    proveedores e interesados, mediante encuestas de satisfacción y     otros mecanismos que se habiliten para dicho propósito.</li>
                        <li>Hacer su propia gestión administrativa, contable y fiscal,    incluyendo, pero sin limitarse a gestión de facturación, gestión de    cobros y pagos, gestión de proveedores, gestión de clientes e     informes a autoridades fiscales.</li>
                        <li>Vincular personal a la empresa y cumplir con sus obligaciones    legales como empleador, tales como, pero sin limitarse a gestión de    personal, gestión de temporales y practicantes, gestión de nómina,    prevención de riesgos laborales, formación de personal, afiliaciones     y pagos al sistema de la seguridad social y bienestar laboral.</li>
                        <li>Cumplir con sus obligaciones legales y estatutarias.</li>
                        <li>Atender consultas, quejas y reclamos.</li>
                        <li>Llevar a cabo actividades encaminadas a controlar y minimizar    riesgos en cuanto a la seguridad de las instalaciones físicas y     sitios virtuales de la empresa.</li>
                        <li>Transferir y/o transmitir datos a terceras personas dentro o fuera    del país, en caso de que haya necesidad de hacerlo, siempre que    dichas personas garanticen condiciones idóneas de confidencialidad y     seguridad de la información transferida y/o transmitida.</li>
                    </ol>
                    <p>Las actividades anteriormente mencionadas, para las cuales se utilizanlos datos personales, son realizadas por Ugni o por quienes Ugni contratepara llevarlas a cabo, de acuerdo con sus necesidades. En todo caso, Ugnivigilará que los terceros contratados para hacer el tratamiento dedatos, cumplan con las presentes políticas y con los parámetros establecidos en la ley.</p>
                    <h3>Tratamiento de datos sensibles.</h3>
                    <p>Son datos sensibles aquellos que afectan la intimidad del titular o cuyouso indebido puede generar su discriminación, tales como aquellosrelacionados con su origen racial o étnico, vida sexual, salud, datosbiométricos (es decir aquellos que permiten identificar a una personapor sus rasgos físicos, de voz, de movimiento, tales como: fotos,huellas, firmas, etc.,) o datos relacionados con su convicción religiosa o filosófica u orientación política.</p>
                    <p>El titular de la información tiene derecho a no suministrar informaciónsensible solicitada por Ugni. Igualmente se recomienda al titularabstenerse de remitir información de carácter sensible que no sea necesaria para la prestación de los servicios de Ugni.</p>
                    <h3>Tratamiento de datos de menores de edad.</h3>
                    <p>Ugni solo trata información o datos personales de menores de edad quehayan sido recolectados con el consentimiento expreso de los padres osus representantes legales, según sea el caso, y solo para aquellos fines que sean pertinentes de acuerdo con la presente política.</p>
                    <h3>Autorización para el tratamiento de datos personales.</h3>
                    <p>Ugni informará al titular de la información personal acerca de la presentepolítica y obtendrá la autorización respectiva para hacer el tratamientode sus datos personales. Igualmente informará al titular sobre loscambios que se efectúen a la presente política y obtendrá una nuevaautorización en caso de que el cambio se refiera a la finalidad del tratamiento.</p>
                    <p>La autorización podrá ser obtenida por Ugni mediante documento escrito,vía correo electrónico, info@ugni.ioverbalmente o telefónicamente, o por cualquierotro medio que permita conservar, consultar o probar la respectivaautorización. Así mismo, la autorización del titular podrá sermanifestada mediante conductas inequívocas que permitan concluir demanera razonable que otorgó la autorización. En ningún caso Ugni asimilará el silencio del titular a una conducta inequívoca.</p>
                    <p>Ugni no requerirá autorización del titular de la información personal cuando se trate de:</p>
                    <ol>
                        <li>Información requerida por una entidad pública o administrativa en     ejercicio de sus funciones legales o por orden judicial;</li>
                        <li>Datos de naturaleza pública;</li>
                        <li>Casos de urgencia médica o sanitaria;</li>
                        <li>Tratamiento de información autorizado por la ley para fines     históricos, estadísticos o científicos;</li>
                        <li>Datos relacionados con el Registro Civil de las Personas.</li>
                    </ol>
                    <h3>Confidencialidad.</h3>
                    <p>Ugni no hace pública la información personal de sus empleados, clientes,proveedores, interesados, o cualquier otra persona con la que sostengaalguna relación de cualquier índole y solo dará a conocer dichainformación a aquellas personas autorizadas por Ugni que deban conocerlapara hacer el respectivo tratamiento de la información o a aquellas personas autorizadas por la ley.</p>
                    <h2>DEBERES DE Ugni</h2>
                    <p>Ugni puede ser a la vez responsable y encargado del tratamiento de datospersonales. En caso de que desempeñe ambos roles, deberá cumplir con los deberes legales propios de cada uno de ellos a saber:</p>
                    <h3>Deberes de Ugni como Responsable del Tratamiento</h3>
                    <p>Como Responsable del Tratamiento, Ugni tiene los siguientes deberes:</p>
                    <ol>
                        <li>Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio     del derecho de hábeas data.</li>
                        <li>Solicitar y conservar, en las condiciones previstas en la Ley 1581    de 2012, y sus decretos reglamentarios, copia de la respectiva     autorización otorgada por el Titular;</li>
                        <li>Informar debidamente al Titular sobre la finalidad de la recolección    y los derechos que le asisten por virtud de la autorización     otorgada.</li>
                        <li>Conservar la información bajo las condiciones de seguridad    necesarias para impedir su adulteración, pérdida, consulta, uso o     acceso no autorizado o fraudulento.</li>
                        <li>Garantizar que la información que se suministre al Encargado del    Tratamiento sea veraz, completa, exacta, actualizada, comprobable y     comprensible.</li>
                        <li>Actualizar la información, comunicando de forma oportuna al    Encargado del Tratamiento, todas las novedades respecto de los datos    que previamente le haya suministrado y adoptar las demás medidas    necesarias para que la información suministrada a este se mantenga     actualizada.</li>
                        <li>Rectificar la información cuando sea incorrecta y comunicar lo     pertinente al Encargado del Tratamiento.</li>
                        <li>Suministrar al Encargado del Tratamiento, según el caso, únicamente    datos cuyo Tratamiento esté previamente autorizado de conformidad     con lo previsto en la Ley 1581 de 2012.</li>
                        <li>Exigir al Encargado del Tratamiento en todo momento, el respeto a    las condiciones de seguridad y privacidad de la información del     Titular.</li>
                        <li>Tramitar las consultas y reclamos formulados en los términos     señalados en la Ley 1581 de 2012.</li>
                        <li>Adoptar un manual interno de políticas y procedimientos para    garantizar el adecuado cumplimiento de la ley y en especial, para la     atención de consultas y reclamos.</li>
                        <li>Informar al Encargado del Tratamiento cuando determinada información    se encuentra en discusión por parte del Titular, una vez se haya    presentado la reclamación y no haya finalizado el trámite     respectivo.</li>
                        <li>Informar a solicitud del Titular sobre el uso dado a sus datos.</li>
                        <li>Informar a la Superintendencia de Industria y Comercio cuando se    presenten violaciones a los códigos de seguridad y existan riesgos     en la administración de la información de los Titulares.</li>
                        <li>Cumplir las instrucciones y requerimientos que imparta la     Superintendencia de Industria y Comercio.</li>
                    </ol>
                    <h3>Deberes de Ugni como Encargado del Tratamiento.</h3>
                    <ol>
                        <li>Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio     del derecho de hábeas data.</li>
                        <li>Conservar la información bajo las condiciones de seguridad    necesarias para impedir su adulteración, pérdida, consulta, uso o     acceso no autorizado o fraudulento.</li>
                        <li>Realizar oportunamente la actualización, rectificación o supresión     de los datos en los términos de la ley.</li>
                        <li>Actualizar la información reportada por los Responsables del    Tratamiento dentro de los cinco (5) días hábiles contados a partir     de su recibo.</li>
                        <li>Tramitar las consultas y los reclamos formulados por los Titulares     en los términos señalados en la ley.</li>
                        <li>Adoptar un manual interno de políticas y procedimientos para    garantizar el adecuado cumplimiento de la ley y, en especial, para     la atención de consultas y reclamos por parte de los Titulares.</li>
                        <li>Registrar en la base de datos las leyendas reclamo en trámite en     la forma en que se regula en la ley.</li>
                        <li>Insertar en la base de datos la leyenda información en discusión    judicial una vez notificado por parte de la autoridad competente    sobre procesos judiciales relacionados con la calidad del dato     personal.</li>
                        <li>Abstenerse de circular información que esté siendo controvertida por    el Titular y cuyo bloqueo haya sido ordenado por la Superintendencia     de Industria y Comercio.</li>
                        <li>Permitir el acceso a la información únicamente a las personas que     pueden tener acceso a ella.</li>
                        <li>Informar a la Superintendencia de Industria y Comercio cuando se    presenten violaciones a los códigos de seguridad y existan riesgos     en la administración de la información de los Titulares.</li>
                        <li>Cumplir las instrucciones y requerimientos que imparta la     Superintendencia de Industria y Comercio.  </li>
                    </ol>
                    <h2>DERECHOS DEL TITULAR DE LA INFORMACIÓN</h2>
                    <p>Los titulares de la información tienen los siguientes derechos:</p>
                    <ol>
                        <li>Conocer, actualizar y rectificar los datos personales frente a Ugni.    Este derecho se podrá ejercer, entre otros, frente a datos    parciales, inexactos, incompletos, fraccionados, que induzcan a    error, o aquellos cuyo tratamiento esté expresamente prohibido o no     haya sido autorizado.</li>
                        <li>Solicitar prueba de la autorización otorgada a Ugni, salvo cuando    expresamente se exceptúe como requisito para el Tratamiento de     conformidad con la ley.</li>
                        <li>Ser informado por Ugni, previa solicitud, respecto del uso que les     hayan dado a sus datos personales.</li>
                        <li>Presentar ante la Superintendencia de Industria y Comercio quejas     por infracciones al régimen de protección de datos personales.</li>
                        <li>Revocar la autorización y/o solicitar la supresión del dato personal    cuando en el tratamiento no se respeten los principios, derechos y     garantías constitucionales y legales.</li>
                        <li>Acceder en forma gratuita a los datos personales que hayan sido     objeto de Tratamiento.  </li>
                    </ol>
                    <h2>PROCEDIMIENTOS</h2>
                    <h3>Área responsable de la atención a peticiones, consultas, quejas y reclamos.</h3>
                    <p>El área de Seguridad de la Información, será la responsable de atenderlas peticiones, consultas, quejas y reclamos de los titulares de losdatos, de acuerdo con los procedimientos que se describen a continuación.</p>
                    <h3>Consultas.</h3>
                    <p>El titular de la información o sus causahabientes tienen derecho aconsultar la información personal del titular, tratada por Ugni. Para ello podrá radicar la consulta en la dirección: Carrera 142 No.58 - 20, Bogotá Colombia, o en el teléfono +57 3012277864, o enviarla al correo electrónico: info@ugni.io</p>
                    <p>Al realizar la solicitud, se deberá suministrar la siguiente información:</p>
                    <ul>
                        <li><span>Si se trata del         Titular:</span> Copia del documento de identidad (C.C., T.I.,     C.E. o pasaporte).</li>
                        <li><span>Si se trata del         causahabiente:</span> Copia del documento de identidad, registro     civil de defunción del Titular, documento que acredite la calidad en     que actúa y el número del documento de identidad del titular.</li>
                        <li><span>Si se trata de un         representante legal y/o apoderado:</span> Copia de documento de     identidad válido, poder que lo faculte para el trámite y el número     del documento de identidad del titular.</li>
                    </ul>
                    <p>La consulta será atendida en un término máximo de diez (10) días hábiles contados a partir de la fecha de radicación de la misma.</p>
                    <p>Cuando no fuere posible atender la consulta dentro de dicho término, sele informará al interesado los motivos de la demora y la fecha en que seatenderá la consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.</p>
                    <h3>Reclamos:</h3>
                    <p>El titular de la información tiene derecho a solicitar la actualización,rectificación y supresión de la información de carácter personal, asícomo revocar la autorización otorgada a Ugni. Para ello podrá radicar elreclamo en la dirección: Carrera 142 No. 28 - 58, Bogota - Colombia, o en el teléfono +57 3012277864, oenviarla al correo electrónico: info@ugni.io .</p>
                    <p>Al realizar el reclamo, se deberá suministrar la siguiente información:</p>
                    <ul>
                        <li><span>Si se trata del         Titular:</span> Copia del documento de identidad (C.C., T.I.,     C.E. o pasaporte).</li>
                        <li><span>Si se trata del         causahabiente:</span> Copia del documento de identidad, registro     civil de defunción del Titular, documento que acredite la calidad en     que actúa y el número del documento de identidad del titular.</li>
                        <li><span>Si se trata de un         representante legal y/o apoderado:</span> Copia de documento de     identidad válido, poder que lo faculte para el trámite y el número     del documento de identidad del titular.</li>
                    </ul>
                    <p>Adicionalmente, la solicitud deberá indicar claramente lo que se pretendecon la reclamación, es decir, si se requiere una actualización, unacorrección o supresión del dato o una revocación de la autorización otorgada a Ugni para el Tratamiento de la información personal.</p>
                    <p>Así mismo, en la solicitud se deberá identificar al titular, hacer unadescripción de los hechos que dan lugar a la reclamación, suministrarlos datos de contacto y aportar los documentos que se quiere hacer valer.</p>
                    <p>Si la solicitud resulta incompleta, se requerirá al interesado para quedentro de los cinco (5) días hábiles siguientes a la recepción delreclamo, subsane las fallas. Transcurridos dos (2) meses desde la fechadel requerimiento, sin que el interesado presente la información requerida, se entenderá que ha desistido del reclamo.</p>
                    <p>Una vez recibido el reclamo completo, se incluirá en la base de datos laleyenda “solicitud en trámite” y el motivo de la misma, en un término nomayor a dos (2) días hábiles, Dicha leyenda se mantendrá hasta que la solicitud sea decidida.</p>
                    <p>El término máximo para atender el reclamo será de quince (15) díashábiles contados a partir del día siguiente a la fecha de su recibo.Cuando no fuere posible atender la solicitud dentro de dicho término, seinformará al interesado los motivos de la demora y la fecha en que seatenderá el reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</p>
                    <h2>VIGENCIA DE LA POLITICA DE PROTECCION DE DATOS PERSONALES Y PRIVACIDAD.</h2>
                    <p>La presente política estará vigente desde su divulgación por cualquiermedio. Ugni se reserva el derecho de actualizarla o modificarla encualquier momento, de lo cual dará aviso oportuno por cualquier medio, a los destinatarios de la misma.</p>
                    <h2>VIGENCIA DE LAS BASES DE DATOS DE Ugni</h2>
                    <p>Las bases de datos personales de Ugni estarán vigentes por el período detiempo exigido por la ley que le sea aplicable, o por el contrato quelas regule, o por el tiempo necesario para cumplir con la finalidad dela base de datos de acuerdo con la presente política. En ningún caso Ugnisuprimirá o eliminará bases de datos que por ley deban permanecervigentes durante un tiempo específico, cuando el período de vigenciaestablecido en el contrato que las regula o la finalidad para las cualesfueron creadas, determinen un período de vigencia inferior al legalmente establecido.</p>
                </div>
            </div>
        </div>
        <div className="brand-logo-area  pt-100">
            <div className="container-fluid">
                <div className="container-max">
                    <div className="brand-logo-slider owl-carousel owl-theme">
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo1.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo2.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo3.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo4.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo5.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo6.png" alt="Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>)

export default PrivacyPolicyPage
